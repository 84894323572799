import appStrings from '@/app/utility/string.utility';
export default {
  name: 'ProfileOptionsDetails',
  props: {
    parentData: Object
  },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showModal: false,
      updateMode: false,
      editMode: false,
      addMode:false,
      tab: null,
      orgType: null,
      siteFlag: false,
      leFlag: false,
      ouFlag: false,
      respFlag: false,
      userFlag: false,
      detailsIndex: 0,
      modules: {
        value: null,
        text: null
      },
      profileOption: {
        value: 0,
        text: null
      },
      selectedLevel: {
        value: null,
        text: null
      },
      description: null,
      entity: null,
      value: null,
      showLevels: false,
      profileOptionHdrId: 0,
      profileOptionDtlId: null,
      profileOptionDetails: [{
        profile_option_dtl_id: 0,
        profile_option_level_lookup_meaning: null,
        profile_option_level_lookup: null,
        profile_option_level_id: null,
        entity: null,
        profile_option_value: null,
        active:true
      }],
      profileOptionHeadings: [
        {
          key: 'profile_option_level_lookup_meaning',
          label: 'Level'
        },
        {
          key: 'entity'
        },
        {
          key: 'profile_option_value',
          label: 'Value'
        },
        {
          key: 'active'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      levelList:[
        {
          value: null,
          text: null
        }
      ],
      legalEntity:{
        value: null,
        text: null
      },
      parent_value_set_id:null,
      setTimeVsetCode:null
    }
  },
  mounted() {
    this.getOrganizationType();
    if (this.parentData !== null) {
      this.getProfileOptionById(this.parentData.profile_option_hdr_id);
      this.addMode=true;
    } else {
      this.addMode=false;
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode) {
          if(this.profileOptionHdrId === 0){
            this.addEditProfileOption();
          }
        }
      }
    });
  },
  methods: {
    getProfileOptionById(hdrId) {
      const payload = hdrId
      this.$store.dispatch('defineProfileOptions/getProfileOptionById', payload)
        .then(response => {
          const results = response.data.data;
          this.modules = {
            value: results.module_id,
            text: results.module_name
          },
            this.profileOption = {
              value: results.profile_option_hdr_id,
              text: results.profile_option_name
            },
            this.description = results.profile_option_description,
            this.siteFlag = results.site_flag,
            this.leFlag = results.le_flag,
            this.ouFlag = results.ou_flag,
            this.respFlag = results.res_flag,
            this.userFlag = results.user_flag
          this.profileOptionDetails = results.profile_option_details.map((item) => {
            return {
              entity: item.entity,
              profile_option_dtl_id: item.profile_option_dtl_id,
              profile_option_level_id: item.profile_option_level_id,
              profile_option_level_lookup: item.profile_option_level_lookup,
              profile_option_level_lookup_meaning: item.profile_option_level_lookup_meaning,
              profile_option_value: item.profile_option_value,
              active:item.active

            };
          });
        });

    },
    addEditProfileOption() {
      const payload = {
        module_id: this.modules.value,
        description: this.description,
        profile_option_hdr_id: this.profileOption.value,
        profile_option_name: this.profileOption.text,
        site_flag: this.siteFlag,
        le_flag: this.leFlag,
        ou_flag: this.ouFlag,
        res_flag: this.respFlag,
        user_flag: this.userFlag,
        admin_profile_option_dtl: this.profileOptionDetails.map((item) => {
          return {
            profile_option_dtl_id: item.profile_option_dtl_id,
            profile_option_level_id: item.profile_option_level_id,
            profile_option_level_look_up: item.profile_option_level_lookup,
            profile_option_value: item.profile_option_value,
            active:item.active

          }
        })
      };
      this.loader = true;
      this.$store.dispatch('defineProfileOptions/addEditProfileOptionHdr', payload)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          }
          else {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.profileOptionDetails.push({
          profile_option_dtl_id: 0,
          profile_option_level_lookup_meaning: null,
          profile_option_level_lookup: 0,
          profile_option_level_id: 0,
          entity: null,
          profile_option_value: null,
          active:true
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.profileOptionDetails.splice(index, 1);
      }
    },
    openValueSetModal(index, flag, vsetCode) {
      this.detailsIndex = index;
      this.showModal = flag;
      this.tab = vsetCode;
      this.parent_value_set_id = null;
      if (this.tab === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.tab === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        if(this.profileOptionDetails[this.detailsIndex].profile_option_level_lookup === 'POU'){
          this.legalEntity.value = item.value_set_dtl_id;
          this.legalEntity.text = item.value_code;
          this.setTimeVsetCode = setTimeout(() => {
            this.openValueSetModal(this.detailsIndex,true,'OU_LIST') 
          }, 500);         
        } else{
          this.profileOptionDetails[this.detailsIndex].entity = item.value_code;
          this.profileOptionDetails[this.detailsIndex].profile_option_level_id = item.value_set_dtl_id;
        }
      } else if (this.tab === appStrings.VALUESETTYPE.OU_LIST) {
        this.profileOptionDetails[this.detailsIndex].entity = item.value_code;
        this.profileOptionDetails[this.detailsIndex].profile_option_level_id = item.value_set_dtl_id;
      } else if (this.tab === 'USER_IND') {
        this.profileOptionDetails[this.detailsIndex].entity = item.value_code;
        this.profileOptionDetails[this.detailsIndex].profile_option_level_id = item.value_set_dtl_id;
      } else if (this.tab === 'RES_IND') {
        this.profileOptionDetails[this.detailsIndex].entity = item.value_code;
        this.profileOptionDetails[this.detailsIndex].profile_option_level_id = item.value_set_dtl_id;
      } else if (this.tab === appStrings.VALUESETTYPE.MODULE_LIST) {
        this.modules.text = item.value_code;
        this.modules.value = item.value_set_dtl_id;
      }
    },
    closeValueSetModal() {
      this.showModal = false;
    },
    showLevelList(index) {
      this.detailsIndex = index;
      this.showLevels = true;
    },
    rowSelected(item) {
      this.profileOptionDetails[this.detailsIndex].profile_option_level_lookup_meaning = item.text;
      this.profileOptionDetails[this.detailsIndex].profile_option_level_lookup = item.value;
      this.profileOptionDetails[this.detailsIndex].profile_option_level_id = null;
      this.profileOptionDetails[this.detailsIndex].entity = null;
      this.showLevels = false;
    },
    getOrganizationType() {
      this.$store
        .dispatch('shared/getDataFromLookupsByLookupType', 'PROFILE_OPTION_LEVEL')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const levelList = results.map(type => {
              return {
                value: type.lookup_code.toUpperCase(),
                text: type.meaning
              };
            });
            this.levelList = levelList;
          }
        });
    },
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.setTimeVsetCode);
  }
}