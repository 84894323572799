import commonHelper from '@/app/utility/common.helper.utility';
import ProfileOptionsDetails from './profileOptionsDetails';
import ModulesList from '../responsibility/searchModule';
export default {
  name: 'ProfileOptions',
  components: {
    ModulesList,
    ProfileOptionsDetails
  },
  watch: {
    currentPage: function() {
      this.getProfileOptionGridData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getProfileOptionGridData();
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      loader: false,
      unsubscribe: null,
      showModal: false,
      payload: {},
      modules: {
        value: null,
        text: null
      },
      profileOptionName: null,
      siteFlag: null,
      leFlag: null,
      ouFlag: null,
      respFlag: null,
      userFlag:null,
      tab: null,
      orgType: null,
      profileOptionRecord: null,
      profileOptionsRecord: [],
      profileOptionsFields: [
        {
          key: 'module_name'
        },
        {
          key: 'profile_option_name',
          label: 'Profile Option'
        },
        {
          key: 'description'
        },
        {
          key: 'site_flag'
        },
        {
          key: 'le_flag',
          label:'LE Flag'
        },
        {
          key: 'ou_flag',
          label:'OU Flag'
        },
        {
          key: 'res_flag',
          label:'Responsibility Flag'
        },
        {
          key: 'user_flag'
        }
      ]
    };
  },
  mounted() {
    this.getProfileOptionGridData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.openShowModal(true, 'ProfileOptionsDetails');
          this.profileOptionRecord = null;
        }
      }
    });
  },
  methods: {
    getProfileOptionGridData() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        module_id: this.modules.value,
        profile_option_name: this.profileOptionName,
        site_flag:
          this.siteFlag == null ? null : this.siteFlag == 'false' ? 0 : 1,
        le_flag:
          this.leFlag == null ? null : this.leFlag == 'false' ? 0 : 1,
        ou_flag:
          this.ouFlag == null ? null : this.ouFlag == 'false' ? 0 : 1,
        res_flag:
          this.respFlag == null ? null : this.respFlag == 'false' ? 0 : 1,
        user_flag:
          this.userFlag == null ? null : this.userFlag == 'false' ? 0 : 1
      };
      this.loader = true;
      this.$store
        .dispatch('defineProfileOptions/getProfileOptionGridData', this.payload)
        .then(response => {
          this.loader = false;
          this.profileOptionsRecord = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(data) {
      this.openShowModal(true, 'ProfileOptionsDetails');
      this.profileOptionRecord = data;
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
    },
    selectedRow(item) {
      if (this.tab === 'ModulesList') {
        this.modules = {
          value: item.module_id,
          text: item.module_name
        };
      }
      this.showModal = false;
    },
    clearFilters() {
      this.profileOptionsRecord = null;
      this.profileOptionName = null;
      this.modules.text = null;
      this.modules.value = null;
      this.userFlag=null;
      this.siteFlag = null;
      this.leFlag = null;
      this.ouFlag = null;
      this.respFlag = null;
      this.totalRows = null;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
